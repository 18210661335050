.logo {
	border-radius: 50%;
	display: block;
	margin: 0;
	padding: 0;
	background: rgb(249,255,0);
background: linear-gradient(128deg, rgba(249,255,0,1) 20%, rgba(14,255,0,1) 56%, rgba(44,255,0,1) 80%);
object-fit: cover;
object-position: top;
}
