:root {
	/* ------- colors ------- */
	--primary-color: #fff;
	--secondary-color: #9a9a92;
	--tertiary-color: #53534b;
	--quaternary-color: #1b1b18;
	--link-color: #eb4759;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Oswald", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
	
}
